@import "../../scss/Variables.scss";

#shell-container { width: 100%; height: 100%; display: flex; flex-direction: column;

  #instruction { flex: 0 0 auto; background-color: white; border-bottom: 2px solid $colorPrimaryLight; padding: 24px; display: flex; align-items: center;

    .content {
      @include adaptiveWidth(980);
      margin: 0 auto;

      p { font-size: 1.2em; text-align: left; }
    }
  }

  #gp-container { flex: 1 0 auto; position: relative; width: 100%; overflow-x: hidden; display: flex; flex-direction: row;

    #gp-wrapper { flex: 1 1 auto; display: inline-block; padding: 16px; position: relative; z-index: 10; width: calc(70% - 24px);
      > div { padding: 0; }
    }

    #feedback-container { flex: 0 0 30%; height: 100%; background-color: #fff; z-index: 1; transform: translateX(20%); display: flex; flex-direction: column; justify-content: flex-start;

      #feedback-display { }

      .navigation-button {  padding: 16px;
        .btn { max-width: 200px; margin: 0 auto; }
      }

      &.--animate-feedback { transform: translateX(0); transition: transform 0.2s ease-out; }
    }
    
  }
  #exercise-identifier { flex: 0 0 auto; }

}

@media screen and (min-width: 769px) {
  #shell-container #gp-container{padding-bottom: 0!important;}
}

@media screen and (max-width: 768px) {
  #shell-container {
    #gp-container { flex-direction: column; 
      #gp-wrapper { flex: 1 0 auto; width: calc(100% - 24px);}
      #feedback-container {width: 100%; z-index: 300; position: fixed; left: 0; bottom: 67px;height: auto; flex: 0 0 0; transform: translate3d(0,100%,0); opacity: 1; transition: all 0.2s ease-out;
        &.--animate-feedback {opacity: 1; transform: translate3d(0,0,0);}
      }
    }
    #exercise-identifier{ }
  }
}
