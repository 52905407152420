@import "./Variables.scss";

.player-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $background-color-secondary;

  .player-header {
    position: relative;
    flex: 0 0 auto;

    .header_close {
      padding: 0 16px 0 0;
      z-index: 10;
      position: relative;

      a {
        display: flex;
        align-items: center;

        span {
          color: #fff;

          &:first-child {
            font-size: 24px;
          }

          &:nth-child(2) {
            font-size: 1em;
          }
        }
      }
    }
  }

  main {
    flex: 1 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .player-container {
    .player-header {
      padding: 8px 0;
      height: 56px;
      align-items: flex-start;

      .header_close {
        z-index: 10;

        span:nth-child(2) {
          display: none;
        }
      }

      #progress-bar {
        position:absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
