@import "../../scss/Variables.scss";

#exercise-identifier {background-color: $background-color-secondary;
  .container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .logo {
      margin-right: auto;
      padding: 8px 0;

      img {
        padding: 0 0 0 8px;
      }
    }

    .identifier {
      padding: 0 8px 0 0;

      span {
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #exercise-identifier {
    width: 100%;

    .container {
      flex-direction: column;

      .logo {
        margin: 0;
      }

      .identifier {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #exercise-identifier {
    .container{
      .logo{
        img{width: 60px;}
      }
    }
  }
}