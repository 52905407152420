@import "../../scss/Variables.scss";

.btn { width: 100%; min-width: 150px; border-radius: 100px; padding: 10px 16px; display: flex; align-items: center; border: 2px solid; transition: all 0.2s ease-out; box-shadow: 3px 3px 0 $colorPrimaryLight;

  span {
    &.label {
      text-align: left;
    }
  }

  &.primary {
    background-color: $colorSecondary;
    border-color: $colorSecondary;
    span {
      color: #fff;

      &.icon-container-btn {
        span {
          font-size: 12px;
        }
      }
    }
    &:hover {
      background-color: #fff;
      span {
        color: $colorSecondary;
      }
    }
  }

  &.secondary {
    span {
      &.icon-container-btn {
        span {
          font-size: 12px;
        }
      }
    }
  }

  &.tertiary {
    background-color: $colorSecondary;
    border-color: #fff;
    span {
      color: #fff;
      &.icon-container-btn {
        span {
          font-size: 12px;
        }
      }
    }
    &:hover {
      background-color: #fff;
      span {
        color: $colorSecondary;
      }
    }
  }
  
  &.centered {
    span {
      &.label {
        width: 100%;
        text-align: center;
      }
    }
  }
}
