@import "../../scss/App.scss";
#feedback-display { display: flex; flex-direction: column; justify-content: flex-start;

  .feedback_correctness { flex: 0 0 auto; position: relative; padding: 30px; color: #fff;

    .icon-container { @include widthHeight(50); position: absolute; left: 24px; bottom: -20px; border-radius: 100%; border: none;
      span { font-size: 50px; }
    }

    &.--incorrect { background-color: $colorError;
      .icon-container { background-color: $colorError; }
    }

    &.--correct { background-color: $colorCorrect;
      .icon-container { background-color: $colorCorrect; }
    }
  }

  > p { padding: 32px 16px 16px 16px;
      span { font-weight: 500;
      &.correct { color: $colorCorrect; }
      &.incorrect { color: $colorError; }
    }
  }

  .feedback__content {
    flex: 0 1 auto; height: 100%; overflow: auto;
    .feedback_explanation {
      padding: 0 16px;
      line-height: 1.4em;

      .content { }
    }
  }

}

@media screen and (min-width: 1280px) {
  #feedback-display {
    .feedback__content {max-height: 545px;}
  }
}
@media screen and (max-width: 1279px) {
  #feedback-display {
    .feedback__content {max-height: 380px;}
  }
}

@media screen and (max-width: 768px) {
  #feedback-display {
    .feedback__content {max-height: 200px;}
  }
}
