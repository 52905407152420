@import '../../scss/Variables.scss';

.header_breadcrumbs {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    > span{ padding: 0 8px 0 0;}
    span{ color: #fff;}
    button{position: relative; cursor: help;
        span{font-size: 24px;}
        .info{opacity: 0; z-index: -1; min-width: 200px; position: absolute; top: 32px; left:-24px; background-color: #fff; padding: 16px; color: $defaultFontColor; @include borderRadius; text-align: left; transition: all 0.2s ease-out; border: 1px solid $colorPrimary;
            &.show{opacity: 1; z-index: 100;}
            &:after{/*css triangle*/
                content: "";
                display: inline-block;
                height: 0;
                width: 0;
                border-right: 5px solid transparent;
                border-bottom: 9px solid #fff;
                border-left: 5px solid transparent;
                position: absolute;
                top: -9px;
                left: 31px;}
        }
    }
  }