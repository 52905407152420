@import "../../scss/App.scss";

#playlist-summary-container {display: flex; align-items: center; justify-content: center; height: 100%;
  .content{ background-color: #fff; @include adaptiveWidth(550); padding: 0 0 32px 0; position: relative;
    .icon{position: relative; background-color: $colorPrimaryLight; height: 170px;
      img{display: block; width:150px; height: auto; position: absolute; left: 50%; transform: translate3d(-50%,-85px,0);}
    }
    h1{margin: 16px 0 16px 0; font-size: 1em; text-align: center;
      span{display: block;
        &:first-child{font-size: 2.8em;}
        &:last-child{font-size: 1.14em;}
      }
    }
    p{/*TEMP*/display:none;/*TEMP*/; padding: 0 32px 8px 32px; margin: 16px 0 0 0; text-align: center;}
    ul{/*TEMP*/display:none;/*TEMP*/ padding: 0 32px; max-height: 150px; overflow: auto; width: 50%; margin: 0 auto 16px auto;
      li{text-align: center;
        span{
          &.--correct{color: $colorCorrect;}
          &.--incorrect{color: $colorError;}
        }
      }
    }
    nav{ @include adaptiveWidth(250); display: flex; justify-content: center; flex-direction: column; margin: 0 auto;
      button{margin: 0 0 16px 0;
        &:last-child{margin: 0;}
      }
    }
  }  
}

@media screen and (max-width: 1024px) {
  #playlist-summary-container {d
    .content{
      .icon{ height: 50px;
        img{ width: 50px; }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #playlist-summary-container { 
    .content{max-width: calc(100% - 32px); margin:0 16px;
      .icon{ height: 75px;
        img{ max-width: 110px; width: 100%;}
      }
    }
  }
}