@import '../../scss/Variables.scss';

#footer{background: $colorPrimary;
    .container{ display:flex; padding: 36px 24px 16px 24px; color: #fff; justify-content: center; align-items: center; position: relative;
        .logo{position: absolute; display: flex; align-items: center; top: 50%; margin-top: -15px;
            &.left{ left: 24px;}
            &.right{ right: 24px;
                span{padding: 0 8px 0 0; font-size: 12px;}
            }
            img{width: auto; height: 30px;}
        }
        .links{ 
            > a{width: calc(100% - 32px); border-radius: 100px; padding: 10px 16px; display: flex; align-items: center; border: 2px solid; transition: all 0.2s ease-out; box-shadow: 3px 3px 0 $colorPrimaryLight; margin: 0 auto;
                background-color: $colorSecondary; 
                span{
                    &:first-child{}
                    &:nth-child(2){padding: 0 0 0 4px;}
                }
            }
            nav{margin: 0 auto 16px auto; text-align: center;
                li{
                    a{font-size: 12px;}
                }
            }
        }
    }
    .copyright{padding: 0 0 8px 0; color: #fff; text-align: center; font-size: 12px;}
}

@media screen and (max-width: 767px) {
    #footer{
        .container{top: auto; justify-content: center; flex-direction: column;
            .logo{position: relative; display: block; top: auto; margin: 0 auto;
                &.left{left: auto;}
                &.right{right: auto; display:flex; align-items: center;}
            }
            .logo, .links{margin: 0 auto 16px auto;}
        }
    }
}