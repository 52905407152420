@import "../../scss/Variables.scss";

#exercise-picker {
  flex: 0 0 auto;
  margin-left: 16px;

  option {
    text-transform: capitalize;

    &.option--current {
      font-weight: 700;
    }

    &.option--correct {
      color: $colorCorrect;
    }

    &.option--incorrect {
      color: $colorError;
    }
  }
}
