@import "../../scss/Variables.scss";

.player-header {
  padding: 16px 0;
  width: 100%;
  background-color: $colorPrimary;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
