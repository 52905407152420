@import './Variables.scss';

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url("../fonts/materialIcons/MaterialIcons-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Staatliches";
  font-style: normal;
  font-weight: 400;
  src: local("Staatliches"),
  local("Staatliches-Regular"),
  url("../fonts/staatliches/Staatliches-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"),
  local("Roboto-Regular"),
  url("../fonts/roboto/Roboto-Regular.ttf") format('truetype');
}

*{margin: 0; padding: 0;}

html{ height: 100%;}
body{ height: 100%; font-family: 'Roboto', sans-serif; @include fontSize($size: var(--base-font-size)); background: #F2F6FB;}

//HEADING
h1, h2{font-family: "Staatliches"; font-weight: 500;}
.roboto{ font-family: "Roboto";}
h2{font-size: 1.7em;}
p, li{line-height: 1.4em;}
button{background: none; border: none; cursor: pointer; outline: none}

// ICONS
.material-icons { font-family: 'Material Icons'; font-weight: normal; font-style: normal; font-size: 24px; line-height: 1; letter-spacing: normal; text-transform: none; display: inline-block; white-space: nowrap; word-wrap: normal; direction: ltr; -webkit-font-feature-settings: 'liga'; -webkit-font-smoothing: antialiased; }
.icon-container-btn{margin-left:auto; border-radius: 100%; width: 20px; height: 20px; border:2px solid; display: flex; align-items: center; justify-content: center;}

// LISTS
ul{list-style-type: none,}
li{list-style: none; list-style-type: none }

// LINKS
a{text-decoration: none;color: inherit;
  &:visited{color: inherit;}
}

// Rules to have a sticky FOOTER
#root{ height: 100%;  display: flex; flex-direction: column; 
  #content{flex: 1 0 auto;}
  #footer{flex-shrink: 0;}
}

