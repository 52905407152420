@import "../../scss/Player.scss";

#playlist-player-container.player-container {
  .player-header {
    .breadcrumbs-container {
      display: flex;
      justify-content: flex-start;

      .header_breadcrumbs {
        padding: 0 0 0 16px;
        z-index: 10;
        position: relative;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .player-container {
    .player-header {
      .breadcrumbs-container {
        order: -2;
      }

      .header_close {
        order: -1;
      }
    }
  }
}
