@import "../../scss/Variables.scss";

#progress-bar {
  flex: 1 1 0;
  margin: 0 32px;
  position: relative;
  max-width: 600px;

  .progress-bar__gauge {
    width: 100%;
    background-color: white;
    border-radius: 100px;
    height: 8px;
    overflow: hidden;

    .gauge__completed {
      background-color: $colorSecondary;
      height: 8px;
    }
  }

  .progress-bar__current-exercise {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 24px;
      color: #fff;
    }

    &.current-exercise--correct {
      background-color: $colorCorrect;
    }

    &.current-exercise--incorrect {
      background-color: $colorError;
    }

    &.current-exercise--solving {
      background-color: $undefined;
    }
  }
}

.player--completed {
  #progress-bar {
    .progress-bar__current-exercise {
      display: none;
    }
  }
}

@media screen and (max-width: 950px) {
  #progress-bar {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  #progress-bar {
    width: 80%;
    margin: 8px auto;

    .progress-bar__current-exercise {
      width: 24px;
      height: 24px;

      span {
        font-size: 16px;
      }
    }
  }
}
