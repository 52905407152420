// COLORS
$colorPrimary:var(--color-primary);
$colorPrimaryLight:var(--color-primary-light);
$colorSecondary:var(--color-secondary);
$colorCorrect:var(--color-correct);
$colorError:var(--color-incorrect);

$background-color-primary:#fff;
$background-color-secondary:#f2f6fb;

$undefined: #979797;

// FONTS
@mixin fontSize($size) {
	font-size: $size;
};
$defaultFontColor: #444;

// OTHER
@mixin borderRadius {
	border-radius: 3px;
}

@mixin widthHeight ($size) {
	width: $size + px;
	height: $size + px;
}

@mixin adaptiveWidth ($maxWidth) {
	width: 100%;
	max-width: $maxWidth + px;
}

@mixin material-icons {
	direction: ltr;
	display: inline-block;
	font-family: 'Material Icons';
	font-size: 24px;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1;
	text-transform: none;
	white-space: nowrap;
	word-wrap: normal;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
  }
  