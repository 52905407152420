@import '../../scss/Variables.scss';

#home-content{
    #top-content{padding: 60px 24px 220px 24px; background-color: $background-color-secondary;
        .content{@include adaptiveWidth(980); margin: 0 auto; display: flex; align-items: center; 
            .block{color: #fff;
                h1{margin: 0 0 8px 0; color: $colorPrimary; font-size: 2.8em;}
                p{color: $defaultFontColor; font-size: 1em;}
            }
        }
    }
    #entries{display: flex; background: $colorPrimary; justify-content: center; padding: 0 0 0 0;
        .card-entry{@include adaptiveWidth(250); margin: 0 2%; position: relative; top:-100px}
    }
    #secondary-content{
        .container{padding: 80px 0;
            .block{display: flex; padding: 24px; @include adaptiveWidth(980); margin: 0 auto; 
                > div{width: 50%;}
                .img{display: flex; align-items: center; justify-content: center;}
                .content{
                    h2{margin: 0 0 24px 0;}
                    p{margin: 0 0 16px 0; }
                    ul{
                        li{ margin: 0 0 8px 0; display: flex; align-items:flex-start ;
                            &:before{ content:'done'; @include material-icons; background-color: $colorPrimary; border-radius: 100%; padding: 2px; color: #fff; margin: 0 8px 0 0;}
                        }
                    }
                }
            }
            &:nth-child(even){background: #fff;}
            &:nth-child(odd){
                .img{order:2}
                .content{order:1}
            }
        }
    }
}

@media screen and (max-width: 980px) {
    #home-content {
        #secondary-content{
            .container {
                .block{width: auto; max-width: 100%;}
            }
        }
        #entries{
            .card-entry{max-width: 220px;}
        }
    }
}

@media screen and (max-width: 767px) {
    #home-content{
        #top-content{padding: 24px; margin: 0 0 24px 0;
            .content{
                .block{ }   
            }
        }
        #entries{ display: block; padding: 24px 0;
            .card-entry{max-width: 245px; margin: 100px auto 24px auto; top: 0;
                &:last-child{margin-bottom: 0;}
            }
        }
        #secondary-content{
            .container{padding: 60px 16px;
                .block{display: block; max-width: 100%; padding: 24px 0;
                    > div{width: 100%;}
                    .img{margin: 0 auto 16px auto;}
                }
            }
        }
    }
  }

  @media screen and (max-width: 567px) {
    #home-content{
        #top-content{
            .content{
                .block{
                    &:last-child{display: none;}
                }
            }
        }
    }
  }